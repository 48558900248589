import { AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import errorMessages from '../../error_messages.json';
import AxiosConfigurator from '../../services/AxiosConfigurator';
import ManageErrorType from '../../services/errorHelper';
import * as swagger from '../../swagger-client';
import { Organization, PagedOrganizationResults } from '../../swagger-client';
import { AppState } from '../index';
import { toggleRouteBack, addNewOrganizationToMySuspription } from '../system/actions';
import * as typesSystem from '../system/types';
import { ERROR_ACTION } from '../system/types';
import * as types from './types';

export const getOrganization = (orgId: number): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState) => {
    const state = getState();
    if (state.system !== null && state.system.user !== null) {
      const orgApi = new swagger.OrganizationApi(AxiosConfigurator.getConfig(state.system.user.token));
      let org = null;

      let query: any = {
        filterOptions: window.btoa(
          JSON.stringify([
            {
              field: 'id',
              value: orgId,
              operation: 'equal',
            },
          ])
        ),
      };
      try {
        const result = await orgApi.get(state.system.user.token as any, { query });
        org = (result.data as PagedOrganizationResults).values[0];
        dispatch({
          type: types.GET_ORG_DETAIL_SUCCESSFUL,
          payload: org,
        });
      } catch (err) {
        const error = ManageErrorType(err as any);
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.manageOrg + '--' + error,
        });
      }
    }
  };
};

export const updateOrganization = (org: Organization): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState) => {
    const state = getState();
    if (state.system !== null && state.system.user !== null) {
      const orgApi = new swagger.OrganizationApi(AxiosConfigurator.getConfig(state.system.user.token));
      let token = state.system.user.token as any;

      try {
        const result = await orgApi.update(token, org);

        dispatch({
          type: types.UPDATE_ORG_DETAIL_SUCCESSFUL,
          payload: result.data,
        });

        dispatch({
          type: typesSystem.UPDATE_ORGANIZATION,
          payload: result.data,
        });
      } catch (err) {
        const error = ManageErrorType(err as any);
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.manageOrgUpdate + '--' + error,
        });
        throw err;
      }
    }
  };
};

export const getOrganizationByUrlIdentifier = (urlIdentifier: string): Promise<any> => {
  return new Promise(async (res, rej) => {
    const orgApi = new swagger.OrganizationApi(AxiosConfigurator.getConfig());
    try {
      const result: any = await orgApi.findByUrlIdentifier(urlIdentifier);
      res(result.data);
    } catch (err) {
      rej(err);
    }
  });
};

export const addOrganization = (org: Organization): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState) => {
    const state = getState();

    if (state.system !== null && state.system.user !== null) {
      // const terminalApi = new swagger.
      const orgApi = new swagger.OrganizationApi(AxiosConfigurator.getConfig(state.system.user.token));
      const token = state.system.user.token as any;
      try {
        const { data } = await orgApi.create(token, org);

        dispatch({
          type: types.ADD_ORG_DETAIL_SUCCESSFUL,
        });

        dispatch(toggleRouteBack());
        dispatch(addNewOrganizationToMySuspription(data));
      } catch (err) {
        const error = ManageErrorType(err as any);
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.manageOrgAdd + '--' + error,
        });
        throw err;
      }
    }
  };
};

export const getCourts = (name: string): ThunkAction<void, AppState, null, AnyAction> => {
  return async (dispatch: Dispatch<AnyAction>, getState) => {
    const state = getState();

    if (state.system !== null && state.system.user !== null) {
      // const terminalApi = new swagger.
      const orgApi = new swagger.OrganizationApi(AxiosConfigurator.getConfig(state.system.user.token));
      const token = state.system.user.token as any;
      try {
        const result = await orgApi.getCourtsByName(name, token);

        dispatch({
          type: types.GET_COURT_BY_NAME,
          payload: result.data,
        });

        dispatch(toggleRouteBack());
      } catch (err) {
        const error = ManageErrorType(err as any);
        dispatch({
          type: ERROR_ACTION,
          payload: errorMessages.manageOrgAdd + '--' + error,
        });
      }
    }
  };
};

export const clearState = (): types.IClearStateAction => {
  return {
    type: types.CLEAR_STATE,
  };
};
