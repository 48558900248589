import { Button, Card, CardHeader, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Switch, TextField } from '@material-ui/core';
import _ from 'lodash';
import * as queryString from 'query-string';
import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IGeneratedMenuItem } from '../../models/IGeneratedMenuData';
import states from '../../models/states.json';
// Begin Redux init
import { AppState } from '../../store';
import { addOrganization, clearState, getCourts, getOrganization, updateOrganization } from '../../store/manage-org/actions';
import { IManageOrgState as ReduxManageOrgState } from '../../store/manage-org/types';
import { navigate } from '../../store/system/actions';
import { SystemState } from '../../store/system/types';
import { Court, Organization } from '../../swagger-client';
import { EmailInput } from '../inputs/email/EmailInput';
import { PhoneInput } from '../inputs/phone/PhoneInput';
import styles from './ManageOrg.module.scss';

const mapStateToProps = (state: AppState) => ({
  system: state.system,
  manageOrg: state.manageOrg,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      navigate,
      getOrganization,
      addOrganization,
      updateOrganization,
      clearState,
      getCourts,
    },
    dispatch
  );

interface IManageOrgProps extends RouteComponentProps {
  system: SystemState;
  manageOrg: ReduxManageOrgState;
  navigate: <TProps extends RouteComponentProps<any>>(menuItem: IGeneratedMenuItem, ownProps: TProps) => ThunkAction<void, AppState, null, AnyAction>;
  getOrganization: (id: number) => ThunkAction<void, AppState, null, AnyAction>;
  getCourts: (name: string) => ThunkAction<void, AppState, null, AnyAction>;
  addOrganization: (org: Organization) => ThunkAction<void, AppState, null, AnyAction>;
  updateOrganization: (org: Organization) => ThunkAction<void, AppState, null, AnyAction>;
  clearState: () => void;
}

interface IManageOrgState {
  settingState: boolean;
  constructing: boolean;
  errors: Array<string>;
  isNew: boolean;
  courtName: string;
  org: Organization | any;
  referer: string;
  isLoadingCourt: boolean;
  courts: Array<Court> | any;
  openSuccess: boolean | undefined;
  snackMessage: string;
}
// End Redux init

enum SettlementTypes {
  authorize = 'authorize',
  authorizeAndCapture = 'authorizeAndCapture',
}

class ManageOrg extends React.Component<IManageOrgProps, IManageOrgState> {
  private formRef = React.createRef<HTMLFormElement>();

  constructor(props: IManageOrgProps) {
    super(props);

    const urlParams: any = queryString.parse(this.props.location.search);

    this.state = {
      settingState: false,
      constructing: true,
      isNew: urlParams.new ? true : false,
      errors: [],
      courtName: '',
      isLoadingCourt: false,
      courts: null,
      openSuccess: false,
      snackMessage: '',
      org: {
        name: '',
        description: '',
        referenceNumberLabel: '',
        urlIdentifier: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: 'AL',
        zip: '',
        phone1: '',
        fax1: '',
        email: '',
        website: '',
        sendReceiptToEmailAddresses: '',
        sendDailySettlementEmailAddresses: '',
        ivrPhone: '',
        ivrVoice: 'Female',
        ivrRepEnabled: false,
        ivrRepPhone: '',
        ivrHoursEnabled: false,
        ivrHoursOfOperation: '',
        ccAcceptAmericanExpress: false,
        ccAcceptVisa: false,
        ccAcceptDiscover: false,
        ccAcceptMasterCard: false,
        testMode: false,
        cardPresentSwipedEnabled: true,
        settlementType: SettlementTypes.authorize,
        strategy: '',
        courtId: 0,
        courtCode: '',
        courtName: '',
        useJis: '',
        jisInformation: [
          {
            firstCourtId: '',
            secondCourtId: '',
            registerId: '',
          },
        ],
      },
      referer: urlParams.referer,
    };

    this.onSaveClick = this.onSaveClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.onChangeCourtID = this.onChangeCourtID.bind(this);
    this.handleChangeuseJis = this.handleChangeuseJis.bind(this);
  }

  static getDerivedStateFromProps(newProps: IManageOrgProps, oldState: IManageOrgState) {
    if (oldState.constructing) {
      return {
        constructing: false,
      };
    }

    let newState: any = {};

    if (!_.isEqual(newProps?.manageOrg?.org, oldState.org)) {
      if (oldState.settingState) {
        newState.org = oldState.org;
      } else {
        newState.org = newProps.manageOrg.org;
      }
    }

    if (oldState.settingState) {
      newState.settingState = false;
    }

    return Object.keys(newState).length > 0 ? { ...oldState, ...newState } : null;
  }

  componentWillUnmount() {
    this.props.clearState();
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const idOrg = query.get('id');
    if (!this.state.isNew) {
      this.props.getOrganization(Number(idOrg));
    }
  }

  private handleChangeSearch(event: ChangeEvent<any>) {
    this.setState({ courtName: event.target.value });
  }

  private handleChangeuseJis(event: ChangeEvent<any>) {
    this.setState({
      org: { ...this.state.org, [event.target.name]: event.target.value === 'useJisYes' ? true : false },
      settingState: true,
    });
  }

  private onChangeCourtID(event: ChangeEvent<any>) {
    let jisInformation = this.state.org.jisInformation ? { ...this.state.org.jisInformation[0] } : {};
    if (event.target.name === 'firstCourtId') {
      jisInformation = { ...jisInformation, [event.target.name]: event.target.value };
    }
    if (event.target.name === 'secondCourtId') {
      jisInformation = { ...jisInformation, [event.target.name]: event.target.value };
    }
    if (event.target.name === 'registerId') {
      jisInformation = { ...jisInformation, [event.target.name]: event.target.value };
    }
    this.setState({
      org: { ...this.state.org, ['jisInformation']: [jisInformation] },
      settingState: true,
    });
  }

  private handleChange(event: ChangeEvent<any>) {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      org: { ...this.state.org, [event.target.name]: value },
      settingState: true,
    });
  }

  private async onSearchClick(event: ChangeEvent<any>) {
    const name = this.state.courtName;
    this.setState({ isLoadingCourt: true });
    await this.props.getCourts(name);
    this.setState({ isLoadingCourt: false });
    this.setState({ courts: this.props.manageOrg?.courts });
  }

  private hadleOnBlur = (e: any) => {
    const elementsWithInvalid = this?.formRef?.current?.querySelectorAll(':invalid');
    const errors: Array<string> = [];
    elementsWithInvalid?.forEach((element) => {
      errors.push((element as any).name as string); // Built in interface does not see name but it is present.;
    });
    this.setState({ errors });
    this.handleChange(e);
    return;
  };

  private async onSaveClick() {
    if (this.state.org !== null) {
      this.setState({ errors: [], settingState: true });
      if (this.formRef.current?.checkValidity()) {
        if (this.state.isNew) {
          try {
            await this.props.addOrganization(this.state.org);

            this.setState({ snackMessage: 'Organization created successfully' });
            this.setState({ openSuccess: true });
          } catch (excepcion) {
            this.setState({ snackMessage: '' });
            this.setState({ openSuccess: false });
          }
        } else {
          try {
            await this.props.updateOrganization(this.state.org);
            this.setState({ snackMessage: 'Organization updated successfully' });
            this.setState({ openSuccess: true });
          } catch (excepcion) {
            this.setState({ snackMessage: '' });
            this.setState({ openSuccess: false });
          }
        }

        if (this.state.referer !== undefined && this.state.referer !== null) {
          this.props.history.push({ pathname: this.state.referer, state: { openSuccess: true, snackMessage: this.state.snackMessage } });
        }
      } else {
        const elementsWithInvalid = this?.formRef?.current?.querySelectorAll(':invalid');
        const errors: Array<string> = [];

        elementsWithInvalid?.forEach((element) => {
          errors.push((element as any).name as string); // Built in interface does not see name but it is present.;
        });

        this.setState({ errors });
      }
    }
  }

  render() {
    const org = this.state?.org;
    const options = this.state?.courts?.map((court: any) => {
      return {
        courtCode: court.courtCode,
        name: court.name,
      };
    });
    const defaultProps = {
      options: options,
      getOptionLabel: (option: any) => option.name,
      // defaultValue: { courtCode: org?.courtCode, name: org?.courtCode },
    };

    return (
      <form ref={this.formRef} noValidate autoComplete="off">
        <Card className={styles.header}>
          <CardHeader title="Create Organization" />
        </Card>
        <div className={styles.container}>
          <div className={styles.columnLeft}>
            <div className={styles.row}>
              <Card className={styles.cardContainer}>
                <CardHeader title="General Information" className={styles.cardHeader} />
                <TextField onBlur={this.hadleOnBlur} variant="outlined" className={styles.inputFieldSelect} id="name" label="Name" name="name" error={this.state.errors.findIndex((fieldName) => fieldName === 'name') > -1} required value={org?.name} onChange={this.handleChange} />
                <TextField
                  variant="outlined"
                  className={styles.inputFieldSelect}
                  id="description"
                  label="Description"
                  name="description"
                  multiline
                  rowsMax={4}
                  error={this.state.errors.findIndex((fieldName) => fieldName === 'description') > -1}
                  value={org?.description}
                  onChange={this.handleChange}
                />
                <TextField
                  className={styles.inputFieldSelect}
                  variant="outlined"
                  id="referenceNumberLabel"
                  name="referenceNumberLabel"
                  label="Reference Number Label"
                  error={this.state.errors.findIndex((fieldName) => fieldName === 'referenceNumberLabel') > -1}
                  value={org?.referenceNumberLabel}
                  onBlur={this.hadleOnBlur}
                  onChange={this.handleChange}
                  required
                />
                <TextField
                  className={styles.inputFieldSelect}
                  variant="outlined"
                  id="urlIdentifier"
                  label="URL Identifier"
                  name="urlIdentifier"
                  error={this.state.errors.findIndex((fieldName) => fieldName === 'urlIdentifier') > -1}
                  required
                  helperText="This will be the token used in the URL to identify the customer. It must be unique."
                  value={org?.urlIdentifier}
                  onBlur={this.hadleOnBlur}
                  onChange={this.handleChange}
                />
                <FormControl variant="outlined" className={styles.inputFieldSelect} required>
                  <InputLabel id="strategyLabel">Type</InputLabel>
                  <Select labelId="strategyLabel" id="strategy" label="Type" name="strategy" variant="outlined" required value={org?.strategy ?? ''} onChange={this.handleChange}>
                    <MenuItem value="court">Court</MenuItem>
                  </Select>
                </FormControl>
              </Card>
            </div>
            <div className={styles.row}>
              <Card className={styles.cardContainer}>
                <CardHeader title="Address" className={styles.cardHeader} />
                <TextField
                  onBlur={this.hadleOnBlur}
                  variant="outlined"
                  className={styles.inputFieldSelect}
                  id="addressLine1"
                  name="addressLine1"
                  label="Address"
                  error={this.state.errors.findIndex((fieldName) => fieldName === 'addressLine1') > -1}
                  required
                  value={org?.addressLine1}
                  onChange={this.handleChange}
                />
                <TextField variant="outlined" className={styles.inputFieldSelect} id="addressLine2" name="addressLine2" label="Suite/Unit/Room" error={this.state.errors.findIndex((fieldName) => fieldName === 'addressLine2') > -1} value={org?.addressLine2} onChange={this.handleChange} />
                <TextField variant="outlined" className={styles.inputFieldSelect} id="city" name="city" label="City" required error={this.state.errors.findIndex((fieldName) => fieldName === 'city') > -1} value={org?.city} onChange={this.handleChange} onBlur={this.hadleOnBlur} />
                <FormControl variant="outlined" className={styles.inputFieldSelect} required>
                  <InputLabel id="stateLabel">State</InputLabel>
                  <Select labelId="stateLabel" id="state" label="State" name="state" required value={org?.state ?? ''} onChange={this.handleChange} variant="outlined">
                    {states.map((state) => (
                      <MenuItem key={`state-${state.Code}`} className={styles.inputField} value={state.Code}>
                        {state.State}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField variant="outlined" className={styles.inputFieldSelect} id="zip" name="zip" label="Zip" error={this.state.errors.findIndex((fieldName) => fieldName === 'zip') > -1} required value={org?.zip ?? ''} onChange={this.handleChange} onBlur={this.hadleOnBlur} />
              </Card>
            </div>
            <div className={styles.row}>
              <Card className={styles.cardContainer}>
                <CardHeader title="Contact Information" className={styles.cardHeader} />
                <TextField
                  className={styles.inputFieldSelect}
                  variant="outlined"
                  id="phone1"
                  name="phone1"
                  label="Phone"
                  required
                  error={this.state.errors.findIndex((fieldName) => fieldName === 'phone1') > -1}
                  InputProps={{
                    inputComponent: PhoneInput as any,
                  }}
                  value={org?.phone1}
                  onBlur={this.hadleOnBlur}
                  onChange={this.handleChange}
                />
                <TextField className={styles.inputFieldSelect} variant="outlined" id="fax1" name="fax1" label="Fax" error={this.state.errors.findIndex((fieldName) => fieldName === 'fax1') > -1} value={org?.fax1} onChange={this.handleChange} />
                <TextField
                  className={styles.inputFieldSelect}
                  variant="outlined"
                  id="email"
                  name="email"
                  label="Email"
                  onBlur={this.hadleOnBlur}
                  error={this.state.errors.findIndex((fieldName) => fieldName === 'email') > -1}
                  InputProps={{
                    inputComponent: EmailInput as any,
                  }}
                  required
                  value={org?.email}
                  onChange={this.handleChange}
                />
                <TextField variant="outlined" className={styles.inputFieldSelect} id="website" name="website" label="Website" error={this.state.errors.findIndex((fieldName) => fieldName === 'website') > -1} value={org?.website} onChange={this.handleChange} />
              </Card>
            </div>
            <div className={styles.row}>
              <Card className={styles.cardContainer}>
                <CardHeader title="Notifications" className={styles.cardHeader} />
                <TextField
                  className={styles.inputFieldSelect}
                  id="sendReceiptToEmailAddresses"
                  variant="outlined"
                  name="sendReceiptToEmailAddresses"
                  label="Send Reciepts To"
                  error={this.state.errors.findIndex((fieldName) => fieldName === 'sendReceiptToEmailAddresses') > -1}
                  helperText="Comma seperated list of email addresses."
                  value={org?.sendReceiptToEmailAddresses}
                  onBlur={this.hadleOnBlur}
                  onChange={this.handleChange}
                />
                {/* //TODO - Add to database  */}
                {/* <TextField
                  className={styles.inputFieldSelect}
                  id="sendDailySettlementEmailAddresses"
                  name="sendDailySettlementEmailAddresses"
                  variant="outlined"
                  label="Send Daily Settlements To"
                  error={this.state.errors.findIndex((fieldName) => fieldName === 'sendDailySettlementEmailAddresses') > -1}
                  helperText="Comma seperated list of email addresses."
                  value={org?.sendDailySettlementEmailAddresses}
                  onChange={this.handleChange}
                /> */}
              </Card>
            </div>
          </div>

          <div className={styles.columnRight}>
            <div className={styles.row}>
              <Card className={styles.cardContainer}>
                <CardHeader title="IVR System" className={styles.cardHeader} />
                <TextField className={styles.inputFieldSelect} id="ivrPhone" name="ivrPhone" variant="outlined" label="Phone Number" required error={this.state.errors.findIndex((fieldName) => fieldName === 'ivrPhone') > -1} value={org?.ivrPhone} onChange={this.handleChange} />
                <FormControl variant="outlined" className={styles.inputFieldSelect} required>
                  <InputLabel id="ivrVoiceLabel">Voice</InputLabel>
                  <Select labelId="ivrVoiceLabel" id="ivrVoice" label="Voice" name="ivrVoice" required value={org?.ivrVoice ?? 'Female'} onChange={this.handleChange} variant="outlined">
                    <MenuItem value="Female">Female</MenuItem>
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Alice">Alice</MenuItem>
                  </Select>
                </FormControl>
                <FormControlLabel className={styles.inputFieldSelect} control={<Switch checked={org?.ivrRepEnabled} onChange={this.handleChange} name="ivrRepEnabled" color="primary" />} label="Rep Enabled" />
                <TextField
                  className={styles.inputFieldSelect}
                  variant="outlined"
                  id="ivrRepPhone"
                  name="ivrRepPhone"
                  label="Phone Number"
                  disabled={!this.state?.org?.ivrRepEnabled}
                  error={this.state.errors.findIndex((fieldName) => fieldName === 'ivrPhone') > -1}
                  value={org?.ivrRepPhone}
                  onChange={this.handleChange}
                />
                <FormControlLabel className={styles.inputFieldSelect} control={<Switch checked={org?.ivrHoursEnabled} onChange={this.handleChange} name="ivrHoursEnabled" color="primary" />} label="Hours of Operation Enabled" />
                <TextField
                  className={styles.inputFieldSelect}
                  id="ivrHoursOfOperation"
                  variant="outlined"
                  name="ivrHoursOfOperation"
                  label="Hours of Operation"
                  disabled={!this.state?.org?.hoursOfOperationEnabled}
                  multiline
                  rowsMax={4}
                  error={this.state.errors.findIndex((fieldName) => fieldName === 'ivrHoursOfOperation') > -1}
                  helperText="A breif paragraph that will be read by IVR system to communicate hours of operation."
                  value={org?.ivrRepPhone}
                  onChange={this.handleChange}
                />
              </Card>
            </div>
            <div className={styles.row}>
              <Card className={styles.cardContainer}>
                <CardHeader title="Billing Information" className={styles.cardHeader} />
                <FormControlLabel className={styles.inputFieldSelect} control={<Switch checked={org?.ccAcceptVisa ? org?.ccAcceptVisa : false} onChange={this.handleChange} name="ccAcceptVisa" color="primary" />} label="Accept Visa" />
                <FormControlLabel className={styles.inputFieldSelect} control={<Switch checked={org?.ccAcceptMasterCard ? org?.ccAcceptMasterCard : false} onChange={this.handleChange} name="ccAcceptMasterCard" color="primary" />} label="Accept Mastercard" />
                <FormControlLabel className={styles.inputFieldSelect} control={<Switch checked={org?.ccAcceptAmericanExpress ? org?.ccAcceptAmericanExpress : false} onChange={this.handleChange} name="ccAcceptAmericanExpress" color="primary" />} label="Accept American Express" />
                <FormControlLabel className={styles.inputFieldSelect} control={<Switch checked={org?.ccAcceptDiscover ? org?.ccAcceptDiscover : false} onChange={this.handleChange} name="ccAcceptDiscover" color="primary" />} label="Accept Discover" />
                <FormControlLabel className={styles.inputFieldSelect} control={<Switch checked={org?.cardPresentSwipedEnabled ? org?.cardPresentSwipedEnabled : false} onChange={this.handleChange} name="cardPresentSwipedEnabled" color="primary" />} label="Accept Swiped Credit Cards (Counter)" />
                <FormControl variant="outlined" className={styles.inputFieldSelect} required error={this.state.errors.findIndex((fieldName) => fieldName === 'settlementType') > -1}>
                  <InputLabel id="settlementTypeLabel">Settlement Type</InputLabel>
                  <Select labelId="settlementTypeLabel" label="Settlement Type" id="settlementType" defaultValue={'Auth'} name="settlementType" value={org?.settlementType} onChange={this.handleChange} required variant="outlined">
                    <MenuItem value="authOnlyTransaction">Authorize</MenuItem>
                    <MenuItem value="authCaptureTransaction">Authorize&Capture</MenuItem>
                  </Select>
                </FormControl>
                <FormControlLabel className={styles.inputFieldSelect} control={<Switch checked={org?.testMode ? org?.testMode : false} onChange={this.handleChange} name="testMode" color="primary" />} label="Test Mode" />
              </Card>
            </div>
            <div className={styles.row}>
              <Card className={styles.cardContainer}>
                <CardHeader title="JIS Court Keys" className={styles.cardHeader} />
                <div className={styles.containerJisCard}>
                  <TextField type="number" className={styles.inputFieldSelect} id="firstCourtId" variant="outlined" name="firstCourtId" label="Primary Court ID" value={org?.jisInformation ? org?.jisInformation[0]?.firstCourtId : ''} onChange={this.onChangeCourtID} />
                  <TextField type="number" className={styles.inputFieldSelect} id="secondCourtId" variant="outlined" name="secondCourtId" label="Secondary Court ID" value={org?.jisInformation ? org?.jisInformation[0]?.secondCourtId : ''} onChange={this.onChangeCourtID} />
                  <TextField className={styles.inputFieldSelect} id="registerId" variant="outlined" name="registerId" label="Register ID" value={org?.jisInformation ? org?.jisInformation[0]?.registerId : ''} onChange={this.onChangeCourtID} />
                  <div className={styles.containerJisRadio}>
                    <FormControl>
                      <FormLabel id="radio-buttons-group-label">Using JIS</FormLabel>
                      <RadioGroup name="useJis" value={org?.useJis ? 'useJisYes' : 'useJisNo'} onChange={this.handleChangeuseJis}>
                        <FormControlLabel className={styles.inputFieldSelect} value="useJisYes" control={<Radio color="primary" />} label="Yes" />
                        <FormControlLabel className={styles.inputFieldSelect} value="useJisNo" control={<Radio color="primary" />} label="No" />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </Card>
            </div>
            <div className={styles.saveButton}>
              <Button variant="contained" color="primary" onClick={this.onSaveClick}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ManageOrg));
